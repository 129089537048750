body {
  padding: 0;
  margin: 0;
  font-family: "Source Code Pro", monospace;
  background: #0f172a;
  color: #e2e8f0;
  min-height: 100vh;
}

.decorator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1.5rem;
}

.main {
  position: relative;
  z-index: 1;
}

/* Remove animated background */
