.socialRow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.socialIcon {
  font-size: 1.5rem;
  transition: all 0.2s ease;
}

.socialIcon a {
  color: #94a3b8;
  transition: all 0.2s ease;
  display: flex;
}

.socialIcon a:hover {
  transform: translateY(-2px);
}

.sig a:hover { color: #ffffff; }
.sil a:hover { color: #ffffff; }
.sit a:hover { color: #ffffff; }
