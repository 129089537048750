.titleRow {
  text-align: center;
}

.titleRow span {
  display: block;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.title strong {
  font-weight: 700;
}

.subTitle {
  font-size: 1rem;
  color: #94a3b8;
  font-weight: 300;
  letter-spacing: 1px;
}
