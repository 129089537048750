.contactRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cLink {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  color: #94a3b8;
  background: transparent;
  border: 1px solid #94a3b8;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.cLink:hover {
  color: #ffffff;
  border-color: #ffffff;
}
