.card {
  padding: 1.5rem;
  margin: 0.75rem;
  width: 90%;
  max-width: 400px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.02);
  transition: all 0.2s ease;
}

.card:hover {
  border-color: rgba(255, 255, 255, 0.12);
}
